import React from "react";
import "./Header.css";
function Header() {
  return (
    <>
      <header>
        <h1>Sudoku Solver</h1>
        <h3>Keep Boxes empty if there is nothing</h3>
      </header>
    </>
  );
}

export default Header;
