import React, { useState } from "react";
import "./Board.css";
import solveSudoku from "./solveSudoku";
import alreadySolved from "./alreadySolved";

function Board() {
  const [boards, setBoards] = useState([
    [0, 8, 0, 0, 3, 0, 0, 0, 0],
    [3, 6, 0, 2, 0, 5, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 8, 0],
    [0, 2, 0, 0, 0, 0, 8, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 4, 5],
    [0, 0, 0, 0, 0, 1, 3, 0, 0],
    [6, 0, 0, 0, 0, 0, 0, 7, 0],
    [0, 0, 0, 0, 1, 0, 2, 9, 0],
    [7, 5, 0, 0, 2, 4, 0, 0, 0],
  ]);

  let resetBoard = [
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0],
  ];

  const handleInputChange = (e, rowIndex, colIndex) => {
    const value = parseInt(e.target.value) || 0; // Convert input value to integer or 0 if invalid
    const updatedBoard = [...boards];
    updatedBoard[rowIndex][colIndex] = value;
    setBoards(updatedBoard);
  };

  const handleSolve = async () => {
    const isSolved = alreadySolved(boards);
    if (isSolved) {
      alert("The Sudoku board is already solved.");
    }

    const isSolvable = solveSudoku(boards);
    if (!isSolvable) {
      alert("The Sudoku board cannot be solved.");
    }

    const solvedBoard = [...boards];
    solveSudoku(solvedBoard);
    setBoards(solvedBoard);
  };

  const handleVerify = () => {
    if (alreadySolved(boards)) {
      alert("Your Solution is Correct");
    } else {
      alert("Your Solution is not Correct");
    }
  };

  const handleReset = () => {
    setBoards(resetBoard);
  };
  return (
    <>
      <div className="container">
        <div className="main">
          {boards.map((board, rows) => (
            <div className="border" key={rows}>
              {board.map((brd, cols) => (
                <>
                  <div className="border-right">
                    <input
                      type="number"
                      key={cols}
                      value={brd === 0 ? "" : brd}
                      onChange={(e) => handleInputChange(e, rows, cols)}
                      maxLength={1}
                      min="0"
                      max="9"
                    />
                  </div>
                </>
              ))}
            </div>
          ))}
        </div>
        <div className="btnCont">
          <button onClick={handleSolve}>Solve</button>
          <button onClick={handleVerify}>Verify</button>
          <button onClick={handleReset}>Reset</button>
        </div>
      </div>
    </>
  );
}

export default Board;
