// Function to solve the Sudoku board using backtracking algorithm
const solveSudoku = (board) => {
  // Function to check if a value can be placed in a specific cell
  const isValid = (board, row, col, num) => {
    // Check row and column constraints
    for (let i = 0; i < 9; i++) {
      if (board[row][i] === num || board[i][col] === num) {
        return false;
      }
    }

    // Check 3x3 grid constraints
    const startRow = Math.floor(row / 3) * 3;
    const startCol = Math.floor(col / 3) * 3;
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (board[startRow + i][startCol + j] === num) {
          return false;
        }
      }
    }

    return true;
  };

  // Function to solve the Sudoku board recursively
  const solve = () => {
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (board[row][col] === 0) {
          for (let num = 1; num <= 9; num++) {
            if (isValid(board, row, col, num)) {
              board[row][col] = num;

              if (solve()) {
                return true;
              }

              board[row][col] = 0;
            }
          }

          return false;
        }
      }
    }

    return true;
  };

  // Create a copy of the board to avoid modifying the original array
  // const solvedBoard = [...board];

  // Solve the Sudoku board
  return solve();

  // Return the solved Sudoku board
  // return solvedBoard;
};

// let board = [
//   [0, 8, 0, 0, 3, 0, 0, 0, 0],
//   [3, 6, 0, 2, 0, 5, 0, 0, 0],
//   [0, 0, 9, 0, 0, 0, 0, 8, 0],
//   [0, 2, 0, 0, 0, 0, 8, 0, 0],
//   [0, 0, 0, 0, 0, 0, 0, 4, 5],
//   [0, 0, 0, 0, 0, 1, 3, 0, 0],
//   [6, 0, 0, 0, 0, 0, 0, 7, 0],
//   [0, 0, 0, 0, 1, 0, 2, 9, 0],
//   [7, 5, 0, 0, 2, 4, 0, 0, 0],
// ];
// console.log(solveSudoku(board));
export default solveSudoku;
