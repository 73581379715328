const alreadySolved = (board) => {
  // Check row and column constraints
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      const num = board[row][col];

      // Check if the current cell is empty or the number is not within the valid range
      if (num === 0 || num < 1 || num > 9) {
        return false;
      }

      // Check row constraint
      for (let i = 0; i < 9; i++) {
        if (i !== col && board[row][i] === num) {
          return false;
        }
      }

      // Check column constraint
      for (let i = 0; i < 9; i++) {
        if (i !== row && board[i][col] === num) {
          return false;
        }
      }

      // Check 3x3 grid constraint
      const startRow = Math.floor(row / 3) * 3;
      const startCol = Math.floor(col / 3) * 3;
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          const currentRow = startRow + i;
          const currentCol = startCol + j;
          if (
            (currentRow !== row || currentCol !== col) &&
            board[currentRow][currentCol] === num
          ) {
            return false;
          }
        }
      }
    }
  }

  return true;
};

export default alreadySolved;
